// Import Library
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const comTitle = "Red Belbom";
const aboutTitle = "About PyaarPro";
const aboutDesc = "PyaarPro is a friendly dating theme based on HTML template for the community functionality";
const pageTitle = "Contacts & Support";

const currentDate = new Date();

let AboutInfo = [
    {
        Name: 'Address',
        Details: 'Suite-13 Tropical Center New Elephant Road 1205',
    },
    {
        Name: 'Contact',
        Details: '+30 226 4881 514 www.yoursitename.com',
    },
]

let InfoList = [
    {
        pageName: 'About Us',
        pageLink: '#',
    },
    {
        pageName: 'Our Team',
        pageLink: '#',
    },
    {
        pageName: 'Testimonials',
        pageLink: '#',
    },
    {
        pageName: 'Get in Touch',
        pageLink: '/contact',
    },
    {
        pageName: 'FAQ',
        pageLink: '#',
    },
]

const Footer = () => {

    const [publisherCountries, setPublisherCountries] = useState([]);

    const fetchStartValues = async () => {
        const responsePublisherCountries = await posterServices.getPublishersCountries();
        if (!responsePublisherCountries.code) {
            setPublisherCountries(responsePublisherCountries)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <footer className="footer footer--style2">
            <div className="footer__top bgimg" style={{ backgroundImage: "url(/assets/images/bg-img/footer.jpg)" }}>
                <div className="footer__newsletter wow fadeInUp" data-wow-duration="1.5s">
                    <div className="container">
                        <div className="row g-4 justify-content-center">
                            <div className="col-lg-6 col-12">
                                <div className="footer__newsletter--area justify-content-xxl-center">
                                    <div className="footer__newsletter--title me-xl-4">
                                        <h4>{comTitle}</h4>
                                    </div>
                                    <div className="footer__newsletter--social">
                                        <ul>
                                            {publisherCountries.map((country, index) => (
                                                <li key={index}>
                                                    <a onClick={() => console.log("hola")} style={{ display: 'inline-block', width: '75px', height: '75px', overflow: 'hidden', cursor: 'pointer' }}>
                                                        <i style={{
                                                            backgroundImage: `url(${country.flagUrl})`,
                                                            backgroundSize: 'cover', backgroundPosition: '50% 50%',
                                                            width: '100%', height: '100%',
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        }}>
                                                        </i>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__toparea padding-tb wow fadeInUp" data-wow-duration="1.5s">
                    <div className="container">
                        <div className="row g-5 g-xl-4">
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="footer__item footer--about">
                                    <div className="footer__inner">
                                        <div className="footer__content">
                                            <div className="footer__content--title">
                                                <h4>{aboutTitle}</h4>
                                            </div>
                                            <div className="footer__content--desc">
                                                <p>{aboutDesc}</p>
                                            </div>
                                            <div className="footer__content--info">
                                                {AboutInfo.map((val, i) => (
                                                    <p key={i}><b>{val.Name} :</b> {val.Details}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="footer__item footer--support">
                                    <div className="footer__inner">
                                        <div className="footer__content">
                                            <div className="footer__content--title">
                                                <h4>{pageTitle}</h4>
                                            </div>
                                            <div className="footer__content--desc">
                                                <ul>
                                                    {InfoList.map((val, i) => (
                                                        <li key={i}><Link to={val.pageLink}> {val.pageName}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__bottom wow fadeInUp" data-wow-duration="1.5s">
                <div className="container">
                    <div className="footer__content text-center">
                        <p className="mb-0">&copy; {currentDate.getFullYear()} - Team Italia.</p>
                    </div>
                </div>
            </div>
        </footer>
    );

}

export default Footer;