import { Fragment, useState } from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";

const conTitle = "Ayúdanos a dirigir tu petición técnica.";
const conDesc = "Si tienes alguna duda sobre el uso ilícito de tus datos (como el correo electrónico o número de teléfono).";
const btnText = "Envíar mensaje";

const ContactPage = () => {

    const [contact, setContact] = useState({ name: '', email: '', subject: '', number: '', massage: '' });

    return (
        <Fragment>
            <HeaderTwo />
            <PageHeader title={'Contáctanos'} curPage={'Contáctanos'} />
            <div className="contact-section">
                <div className="contact-top padding-tb aside-bg padding-b">
                    <div className="container">
                        <div className="row g-4 flex-row-reverse">
                            <div className="col-lg-12">
                                <article className="contact-form-wrapper">
                                    <div className="contact-form">
                                        <h4>{conTitle}</h4>
                                        <p className="mb-4">{conDesc}</p>
                                        <form className="comment-form">
                                            <input
                                                type="text"
                                                name="name"
                                                id="item01"
                                                value={contact.name}
                                                onChange={(e) => { setContact({ ...contact, name: e.target.value }) }}
                                                placeholder="Su nombre *"
                                            />
                                            <input
                                                type="text"
                                                name="email"
                                                id="item02"
                                                value={contact.email}
                                                onChange={(e) => { setContact({ ...contact, email: e.target.value }) }}
                                                placeholder="Tu correo electrónico *"
                                            />
                                            <input
                                                type="text"
                                                name="subject"
                                                id="item03"
                                                value={contact.subject}
                                                onChange={(e) => { setContact({ ...contact, subject: e.target.value }); }}
                                                placeholder="Tu asunto *"
                                            />
                                            <input
                                                type="text"
                                                name="number"
                                                id="item04"
                                                value={contact.number}
                                                onChange={(e) => { setContact({ ...contact, number: e.target.value }); }}
                                                placeholder="Número de teléfono *"
                                            />
                                            <textarea
                                                rows="10"
                                                type="text"
                                                id="item05"
                                                name="message"
                                                value={contact.massage}
                                                onChange={(e) => { setContact({ ...contact, massage: e.target.value }); }}
                                                placeholder="Tu mensaje"
                                            ></textarea>
                                            <button type="submit" className="lab-btn"><span>{btnText}</span></button>
                                        </form>
                                        <p className="form-message"></p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default ContactPage;