import { Component } from "react";

const text = "Upload";

class Upload extends Component {
    render() { 
        return (
            <ul className="media-upload mb-5">
                <li className="upload-now">
                    <div className="custom-upload">
                        <div className="file-btn"><i className="icofont-upload-alt"></i>{text}</div>
                        <input type="file" />
                    </div>
                </li>
            </ul>
        );
    }
}
 
export default Upload;