// Import Library
import { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";

// Import Config
import config from "../../config";

let HeaderSocialList = [
    {
        iconName: 'icofont-facebook-messenger',
        link: '#'
    },
    // {
    //     iconName: 'icofont-twitter',
    //     link: '#'
    // },
    // {
    //     iconName: 'icofont-vimeo',
    //     link: '#'
    // },
    // {
    //     iconName: 'icofont-skype',
    //     link: '#'
    // },
    // {
    //     iconName: 'icofont-rss-feed',
    //     link: '#'
    // },
]

const HeaderTwo = (props) => {

    const { geolocation } = props

    let HeaderInfoList = [{
        iconName: 'icofont-location-pin',
        text: geolocation.country_name,
    }]

    const menuTrigger = () => {
        document.querySelector('.menu').classList.toggle('active')
        document.querySelector('.header-bar').classList.toggle('active')
    }

    const menuTriggerTwo = () => {
        document.querySelector('.header-top').classList.toggle('open')
    }

    window.addEventListener('scroll', function () {
        var value = window.scrollY;
        if (value > 200) {
            document.querySelector('.header-section').classList.add(['header-fixed'], ['animated'], ['fadeInDown'])
        } else {
            document.querySelector('.header-section').classList.remove(['header-fixed'], ['animated'], ['fadeInDown'])
        }
    });

    return (
        <Fragment>
            <header className="header-section">
                <div className="header-top bg-theme">
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="left">
                                {HeaderInfoList.map((value, index) => (
                                    <li key={index}>
                                        <i className={value.iconName}></i>Estas en <span>{value.text}</span>
                                    </li>
                                ))}
                            </ul>
                            <ul className="social-icons d-flex align-items-center">
                                <li>
                                    <p>Síguenos :</p>
                                </li>
                                {HeaderSocialList.map((val, index) => (
                                    <li key={index}><a href={val.link}><i className={val.iconName}></i></a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-bottom bg-primary">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo">
                                <Link to="/">
                                    <img src="assets/images/logo/logo.png" alt="logo" style={{ width: '160px', height: '50px', objectFit: 'cover' }} />
                                </Link>
                            </div>
                            <div className="menu-area">
                                <ul className="menu">
                                    <li><NavLink to="/">Inicio</NavLink></li>
                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Features</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/community">Community</NavLink></li>
                                            <li><NavLink to="/members">All Members</NavLink></li>
                                            <li><NavLink to="/profile">Member Profile</NavLink></li>
                                            <li><NavLink to="/group-single">Group Single</NavLink></li>
                                            <li><NavLink to="/policy">Privacy & Policy</NavLink></li>
                                            <li><NavLink to="/404">404 Page</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><NavLink to="/contact">Contactanos</NavLink></li>
                                </ul>
                                <a className="login" href={config.url.panel} target="_blank" rel="noopener noreferrer"><i className="icofont-user"></i> <span>Entra en Belbom</span> </a>
                                <div className="header-bar d-lg-none" onClick={menuTrigger}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ellepsis-bar d-lg-none" onClick={menuTriggerTwo}>
                                    <i className="icofont-info-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    );
}

export default HeaderTwo;