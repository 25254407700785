import { Component } from "react";
import { Link } from "react-router-dom";

const title = "you may like";

let LikeMemberList = [
    {
        imgUrl: 'assets/images/widget/01.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/02.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/03.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/04.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/05.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/06.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/07.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/08.jpg',
        imgAlt: 'Dathing Member',
    },
    {
        imgUrl: 'assets/images/widget/09.jpg',
        imgAlt: 'Dathing Member',
    },
]

class LikeMember extends Component {
    render() { 
        return (
            <div className="widget like-member">
                <div className="widget-inner">
                    <div className="widget-title">
                        <h5>{title}</h5>
                    </div>
                    <div className="widget-content">
                        <div className="row row-cols-3 row-cols-sm-auto g-3">
                            {LikeMemberList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="image-thumb">
                                        <Link to="/profile">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default LikeMember;