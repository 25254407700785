// Import Library
import { useState, useEffect } from 'react'

// Import Web Api
import configurationServices from "../dataservices/configurationServices";
import externalServices from "../dataservices/externalServices";

const useCountry = () => {

    const [country, setCountry] = useState({ data: [], loading: true });
    const [geolocation, setGeolocation] = useState({ data: null, loading: true });

    useEffect(() => {

        const loadCountry = async () => {
            const response = await configurationServices.getCountries();
            if (!response.code)
                setCountry({
                    data: response,
                    loading: false
                });
        }

        const loadGeolocation = async () => {
            const response = await externalServices.getGeolocation();
            if (!response.code) {
                setGeolocation({
                    data: response,
                    loading: false
                });
            }
        };

        loadCountry();
        loadGeolocation();
    }, [])
    return { country, geolocation }
}

export default useCountry