import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../component/layout/pagination";

let CommunityPageList = [
    {
        imgUrl: 'assets/images/story/01.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Dream places and locations to visit in 2022',
        authorImg: 'assets/images/story/author/01.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Umme Nishat',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/02.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Make your dreams come true and monetise quickly',
        authorImg: 'assets/images/story/author/02.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Rajib Raj',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/03.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Love looks not with the eyes, but with the mind...',
        authorImg: 'assets/images/story/author/03.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Radhika Roy',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/04.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Things base and vile, holding no quantity.',
        authorImg: 'assets/images/story/author/01.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Umme Nishat',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/05.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Love can transpose to form and dignity.',
        authorImg: 'assets/images/story/author/02.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Rajib Raj',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/06.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Nor hath Love’s mind of any judgment taste.',
        authorImg: 'assets/images/story/author/03.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Radhika Roy',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/07.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Wings, and no eyes, figure with unheedy haste.',
        authorImg: 'assets/images/story/author/01.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Umme Nishat',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/08.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Another based on sight only no input from the mind.',
        authorImg: 'assets/images/story/author/02.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Rajib Raj',
        postDate: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/09.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Love one of the primary drivers of the play.',
        authorImg: 'assets/images/story/author/03.png',
        authorImgAlt: 'Aothor Thumb',
        authorName: 'Radhika Roy',
        postDate: 'April 08, 2022',
    },
]

class CommunityPage extends Component {
    render() { 
        return (
            <Fragment>
                <HeaderTwo />
                <PageHeader title={'Community Page'} curPage={'Community Page'} />
                <section className="story-section padding-tb">
                    <div className=" container">
                        <div className="section-wrapper">
                            <div className="row justify-content-center g-4 row-cols-xl-3 row-cols-sm-2 row-cols-1">
                                {CommunityPageList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="story-item lab-item">
                                            <div className="lab-inner">
                                                <div className="lab-thumb">
                                                    <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                                </div>
                                                <div className="lab-content">
                                                    <Link to="/blog-single"><h4>{val.title}</h4></Link>
                                                    <div className="lab-content-author">
                                                        <div className="left">
                                                            <img src={`${val.authorImg}`} alt={`${val.authorImgAlt}`} />
                                                        </div>
                                                        <div className="right">
                                                            <Link to="/profile">{val.authorName}</Link>
                                                            <span>{val.postDate}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination />
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default CommunityPage;