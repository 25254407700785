import http from "../comunication/http-common";
import config from "../config";

const getCountries = async () => {
    const url = config.url.gateway + "/country/all";
    return await http.get(url)
        .then((result) => result.data)
        .catch((error) => { return { "code": error.response.data.type, "message": error.response.data.title } });
};

const getCatalogs = async (business) => {
    const url = config.url.gateway + "/catalog/";
    return await http.get(`${url}${business}`)
        .then((result) => result.data)
        .catch((error) => { return { "code": error.response.data.type, "message": error.response.data.title } });
};

export default { getCountries, getCatalogs }