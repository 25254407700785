import http from "../comunication/http-common";
import config from "../config";

const getPublishersCountries = async () => {
    const url = config.url.gateway + "/publisher/countries";
    return await http.get(url)
        .then((result) => result.data)
        .catch((error) => { return { "code": error.response.data.type, "message": error.response.data.title } });
}

const getTopProfileByCountry = async (codeCountry) => {
    const url = config.url.gateway + "/publisher/top-country";
    return await http.get(url, {
        params: {
            codeCountry: codeCountry
        }
    })
        .then((result) => result.data)
        .catch((error) => { return { "code": error.response.data.type, "message": error.response.data.title } });
}

const getCityPosters = async (codeCountry) => {
    const url = config.url.gateway + "/publisher/city-posters";
    return await http.get(url, {
        params: {
            codeCountry: codeCountry
        }
    })
        .then((result) => result.data)
        .catch((error) => { return { "code": error.response.data.type, "message": error.response.data.title } });
}

export default { getPublishersCountries, getTopProfileByCountry, getCityPosters }