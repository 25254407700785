// Import Library
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

//Import Config
import config from "../../config";

const title = "Mapa";
const desc = "";

const Map = () => {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.googleMaps.key,
        libraries: config.googleMaps.places
    });

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    return (
        <div className="about padding-tb">
            <div className="container">
                <div className="section-header wow fadeInUp" data-wow-duration="1.5s">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>

                <div className="section__wrapper">
                    <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
                        <GoogleMap
                            mapContainerStyle={config.googleMaps.containerStyle}
                            center={config.googleMaps.center}
                            zoom={config.googleMaps.zoom}
                            options={config.googleMaps.options}
                        >
                        </GoogleMap>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Map;