import { Link } from "react-router-dom";

const PageHeader = (props) => {

    const { title } = props;
    const { curPage } = props;

    return (
        <section className="page-header-section style-1 bgimg" style={{ backgroundImage: "url(/assets/images/bg-img/pageheader.jpg)" }}>
            <div className="container">
                <div className="page-header-content">
                    <div className="page-header-inner">
                        <div className="page-title">
                            <h2>{title}</h2>
                        </div>
                        <ol className="breadcrumb">
                            <li><Link to="/">Inicio</Link></li>
                            <li className="active">{curPage}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageHeader;