import { Component, Fragment } from "react";
import { Link } from "react-router-dom";


import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";
import Upload from "../component/layout/upload";
import ActiveGroup from "../component/sidebar/active-group";
import FilterSearch from "../component/sidebar/filtersearch";
import LikeMember from "../component/sidebar/like-member";

const btnText = "Load More";
const opps = " Sorry !! There's no media found for the request !! ";

let MemberList = [
    {
        imgUrl: 'assets/images/member/01.jpg',
        imgAlt: 'Dating Member',
        name: 'jenifer Guido',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'Dating Member',
        name: 'Andrea Guido',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/03.jpg',
        imgAlt: 'Dating Member',
        name: 'Anna hawk',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/04.jpg',
        imgAlt: 'Dating Member',
        name: 'Andreas Adam',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/05.jpg',
        imgAlt: 'Dating Member',
        name: 'Alaina T',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/06.jpg',
        imgAlt: 'Dating Member',
        name: 'Aron Smith',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/07.jpg',
        imgAlt: 'Dating Member',
        name: 'Helen Gomz',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/08.jpg',
        imgAlt: 'Dating Member',
        name: 'Andrez jr',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/09.jpg',
        imgAlt: 'Dating Member',
        name: 'Ladiga Guido',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/10.jpg',
        imgAlt: 'Dating Member',
        name: 'Andrea Guido',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/11.jpg',
        imgAlt: 'Dating Member',
        name: 'Jene Aiko',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/12.jpg',
        imgAlt: 'Dating Member',
        name: 'Jhon Cena',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/13.jpg',
        imgAlt: 'Dating Member',
        name: 'Irfan Patel',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/14.jpg',
        imgAlt: 'Dating Member',
        name: 'Hames Radregez',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/15.jpg',
        imgAlt: 'Dating Member',
        name: 'Johan ben',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/16.jpg',
        imgAlt: 'Dating Member',
        name: 'Johannes',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/17.jpg',
        imgAlt: 'Dating Member',
        name: 'Helena Mind',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/18.jpg',
        imgAlt: 'Dating Member',
        name: 'Virat Alba',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/19.jpg',
        imgAlt: 'Dating Member',
        name: 'Afrin Nawr',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/20.jpg',
        imgAlt: 'Dating Member',
        name: 'Jason Roy',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/01.jpg',
        imgAlt: 'Dating Member',
        name: 'jenifer Guido',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'Dating Member',
        name: 'Andrea Guido',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/03.jpg',
        imgAlt: 'Dating Member',
        name: 'Anna hawk',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/04.jpg',
        imgAlt: 'Dating Member',
        name: 'Andreas Adam',
        activity: 'Active 1 Day',
    },
]

let AlbumList = [
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'Dating Member',
        title: 'Private',
    },
    {
        imgUrl: 'assets/images/member/04.jpg',
        imgAlt: 'Dating Member',
        title: 'Crush',
    },
    {
        imgUrl: 'assets/images/member/06.jpg',
        imgAlt: 'Dating Member',
        title: 'Public',
    },
    {
        imgUrl: 'assets/images/member/08.jpg',
        imgAlt: 'Dating Member',
        title: 'Favorite',
    }
]

let GalleryList = [
    {
        imgUrl: 'assets/images/member/01.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/03.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/04.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/05.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/06.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/07.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/08.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/09.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/10.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/11.jpg',
        imgAlt: 'Dating Member',
    },
    {
        imgUrl: 'assets/images/member/12.jpg',
        imgAlt: 'Dating Member',
    },
]

class GroupSingle extends Component {
    render() { 
        return (
            <Fragment>
                <HeaderTwo />
                <PageHeader title={'Group Single'} curPage={'Group Single'} />
                <section className="profile-section padding-tb">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="member-profile">
                                <div className="profile-item">
                                    <div className="profile-cover">
                                        <img src="assets/images/group/cover.jpg" alt="cover-pic" />
                                        <div className="edit-photo custom-upload">
                                            <div className="file-btn"><i className="icofont-camera"></i>Edit Photo</div>
                                            <input type="file" />
                                        </div>
                                    </div>
                                    <div className="profile-information">
                                        <div className="profile-pic">
                                            <img src="assets/images/group/single.jpg" alt="rajibraj91" />
                                            <div className="custom-upload">
                                                <div className="file-btn">
                                                    <span className="d-none d-lg-inline-block"> <i className="icofont-camera"></i>Edit</span>
                                                    <span className="d-lg-none mr-0"><i className="icofont-plus"></i></span>
                                                </div>
                                                <input type="file" />
                                            </div>
                                        </div>
                                        <div className="profile-name">
                                            <h4>Be Young, Love Life</h4>
                                            <p className="mb-0"> Public group</p>
                                        </div>
                                        <ul className="profile-contact">
                                            <li>
                                                <a href="#">
                                                    <div className="icon"><i className="icofont-plus"></i></div>
                                                    <div className="text">
                                                        <p>Invaite</p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="icon"><i className="icofont-users"></i></div>
                                                    <div className="text">
                                                        <p>Joined</p>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>

                                <div className="profile-details">
                                    <nav className="profile-nav">
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-ativity-tab" data-bs-toggle="tab" data-bs-target="#activity" type="button" role="tab" aria-controls="activity" aria-selected="true">Home</button>

                                            <button className="nav-link" id="nav-members-tab" data-bs-toggle="tab" data-bs-target="#members" type="button" role="tab" aria-controls="members" aria-selected="false">Members</button>

                                            <button className="nav-link" id="nav-media-tab" data-bs-toggle="tab" data-bs-target="#media" type="button" role="tab" aria-controls="media" aria-selected="false">Media <span className="item-number">35</span></button>

                                            <button className="nav-link" id="nav-forums-tab" data-bs-toggle="tab" data-bs-target="#forums" type="button" role="tab" aria-controls="forums" aria-selected="false">Forums</button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane activity-page fade show active" id="activity" role="tabpanel">
                                            <div className="row">
                                                <div className="col-xl-8">
                                                    <div className="">
                                                        <div className="create-post mb-20">
                                                            <div className="lab-inner">
                                                                <div className="lab-thumb">
                                                                    <div className="thumb-inner">
                                                                        <div className="thumb-img">
                                                                            <img src="assets/images/profile/dp.png" alt="img" className="rounded-circle" />
                                                                        </div>
                                                                        <div className="thumb-content">
                                                                            <h6><a href="#">Zinat Zaara</a></h6>
                                                                            <div className="custom-select">
                                                                                <select>
                                                                                    <option value="1">&#xf02c; Public</option>
                                                                                    <option value="2">&#xec61; Private</option>
                                                                                    <option value="3">&#xed0d; Friends</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="lab-content">
                                                                    <form action="#" className="post-form">
                                                                        <input type="text" placeholder="Whats on your mind. William?" />
                                                                        <div className="content-type">
                                                                            <ul className="content-list">
                                                                                <li className="text"><a href="#"><i className="icofont-edit"></i>Text </a></li>
                                                                                <li className="image-video">
                                                                                    <div className="file-btn"><i className="icofont-camera"></i>Photo/Videos</div>
                                                                                    <input type="file" />
                                                                                </li>
                                                                                <li className="attach-file">
                                                                                    <div className="file-btn"><i className="icofont-paper-clip"></i>Attach File</div>
                                                                                    <input type="file" />
                                                                                </li>
                                                                                <li className="post-submit">
                                                                                    <input type="submit" value="Post" className="lab-btn" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                    
                                                        <div className="post-item mb-20">
                                                            <div className="post-content">
                                                                <div className="post-author">
                                                                    <div className="post-author-inner">
                                                                        <div className="author-thumb">
                                                                            <img src="assets/images/profile/01.jpg" alt="img" />
                                                                        </div>
                                                                        <div className="author-details">
                                                                            <h6><a href="#">Umesh Jadhov</a></h6>
                                                                            <ul className="post-status">
                                                                                <li className="post-privacy"><i className="icofont-world"></i>Public</li>
                                                                                <li className="post-time">6 Mintues Ago</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="post-description">
                                                                    <p>Quickly deliver going forward methods info create empowerment before client-centered bandwdth Credibly pontficate interoperable leadership skills ands B2B data awesome Continually whiteboard ands B2B data awesome Continually whiteboard </p>
                                                                </div>
                                                            </div>
                                                            <div className="post-meta">
                                                                <div className="post-meta-top">
                                                                    <p><a href="#"><i className="icofont-like"></i> <i className="icofont-heart"></i> <i className="icofont-laughing"></i><span>Julia, Petrova and 306 like this</span></a></p>
                                                                    <p><a href="#">136 Comments</a></p>
                                                                </div>
                                                                <div className="post-meta-bottom">
                                                                    <ul className="react-list">
                                                                        <li className="react"><a href="#"><i className="icofont-like"></i>Like</a> </li>
                                                                        <li className="react"><a href="#"><i className="icofont-speech-comments"></i>Comment</a></li>
                                                                        <li className="react"><a href="#"><i className="icofont-share"></i> Share</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="post-item mb-20">
                                                            <div className="post-content">
                                                                <div className="post-author">
                                                                    <div className="post-author-inner">
                                                                        <div className="author-thumb">
                                                                            <img src="assets/images/profile/02.jpg"
                                                                                alt="img" />
                                                                        </div>
                                                                        <div className="author-details">
                                                                            <h6><a href="#">Radika Roy</a></h6>
                                                                            <ul className="post-status">
                                                                                <li className="post-privacy"><i
                                                                                        className="icofont-world"></i>
                                                                                    Public</li>
                                                                                <li className="post-time">6 Mintues Ago
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="post-description">
                                                                    <p>Quickly deliver going forward methods info
                                                                        create empowerment before client-centered
                                                                        bandwdth
                                                                        Credibly pontficate interoperable leadership
                                                                        skills ands B2B data awesome Continually
                                                                        whiteboard
                                                                        ands B2B data awesome Continually whiteboard
                    
                                                                    </p>
                                                                    <div className="post-desc-img">
                                                                        <img src="assets/images/profile/post-image/01.jpg"
                                                                            alt="img" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="post-meta">
                                                                <div className="post-meta-top">
                                                                    <p><a href="#"><i className="icofont-like"></i> <i className="icofont-heart"></i> <i className="icofont-laughing"></i><span>Julia, Petrova and 306 like this</span></a></p>
                                                                    <p><a href="#">136 Comments</a></p>
                                                                </div>
                                                                <div className="post-meta-bottom">
                                                                    <ul className="react-list">
                                                                        <li className="react"><a href="#"><i className="icofont-like"></i> Like</a> </li>
                                                                        <li className="react"><a href="#"><i className="icofont-speech-comments"></i> Comment </a></li>
                                                                        <li className="react"><a href="#"><i className="icofont-share"></i> Share </a> </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                    
                                                        <div className="post-item mb-20">
                                                            <div className="post-content">
                                                                <div className="post-author">
                                                                    <div className="post-author-inner">
                                                                        <div className="author-thumb">
                                                                            <img src="assets/images/profile/03.jpg" alt="img" />
                                                                        </div>
                                                                        <div className="author-details">
                                                                            <h6><a href="#">Alex Smith</a></h6>
                                                                            <ul className="post-status">
                                                                                <li className="post-privacy"><i className="icofont-world"></i>Public</li>
                                                                                <li className="post-time">6 Mintues Ago</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="post-description">
                                                                    <p>Quickly deliver going forward methods info
                                                                        create empowerment before client-centered
                                                                        bandwdth
                                                                        Credibly pontficate interoperable leadership
                                                                        skills ands B2B data awesome Continually
                                                                        whiteboard
                                                                        ands B2B data awesome Continually whiteboard
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="post-meta">
                                                                <div className="post-meta-top">
                                                                    <p><a href="#"><i className="icofont-like"></i> <i className="icofont-heart"></i> <i className="icofont-laughing"></i><span>Julia, Petrova and 306 like this</span></a></p>
                                                                    <p><a href="#">136 Comments</a></p>
                                                                </div>
                                                                <div className="post-meta-bottom">
                                                                    <ul className="react-list">
                                                                        <li className="react"><a href="#"><i className="icofont-like"></i> Like</a> </li>
                                                                        <li className="react"><a href="#"><i className="icofont-speech-comments"></i> Comment </a></li>
                                                                        <li className="react"><a href="#"><i className="icofont-share"></i> Share</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="post-item mb-20">
                                                            <div className="post-content">
                                                                <div className="post-author">
                                                                    <div className="post-author-inner">
                                                                        <div className="author-thumb">
                                                                            <img src="assets/images/profile/04.jpg" alt="img" />
                                                                        </div>
                                                                        <div className="author-details">
                                                                            <h6><a href="#">Nishat Mili</a></h6>
                                                                            <ul className="post-status">
                                                                                <li className="post-privacy"><i className="icofont-world"></i>Public</li>
                                                                                <li className="post-time">6 Mintues Ago</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="post-description">
                                                                    <p>Quickly deliver going forward methods info  create empowerment before client-centered bandwdth Credibly pontficate interoperable leadership skills ands B2B data awesome Continually whiteboard ands B2B data awesome Continually whiteboard</p>
                                                                    <div className="post-desc-img">
                                                                        <div className="row g-3">
                                                                            <div className="col-md-6">
                                                                                <img src="assets/images/profile/post-image/02.jpg" alt="img" />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <img src="assets/images/profile/post-image/03.jpg" alt="img" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="post-meta">
                                                                <div className="post-meta-top">
                                                                    <p><a href="#"><i className="icofont-like"></i> <i className="icofont-heart"></i> <i className="icofont-laughing"></i><span>Julia, Petrova and 306 like this</span></a></p>
                                                                    <p><a href="#">136 Comments</a></p>
                                                                </div>
                                                                <div className="post-meta-bottom">
                                                                    <ul className="react-list">
                                                                        <li className="react"><a href="#"><i className="icofont-like"></i> Like</a> </li>
                                                                        <li className="react"><a href="#"><i className="icofont-speech-comments"></i>Comment </a></li>
                                                                        <li className="react"><a href="#"><i className="icofont-share"></i> Share</a> </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                    
                                                        <div className="load-btn">
                                                            <a href="#" className="lab-btn">Load More Post <i className="icofont-spinner"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <aside className="mt-5 mt-xl-0">
                                                        <FilterSearch />
                                                        <LikeMember />
                                                        <ActiveGroup />
                                                    </aside>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="members" role="tabpanel" aria-labelledby="nav-members-tab">
                                            <div>
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        <article>
                                                            <div className="row gy-4 gx-3 justify-content-center">
                                                                {MemberList.map((val, i) => (
                                                                    <div className=" col-lg-3 col-md-4 col-6" key={i}>
                                                                        <div className="lab-item member-item style-1">
                                                                            <div className="lab-inner">
                                                                                <div className="lab-thumb">
                                                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                                                </div>
                                                                                <div className="lab-content">
                                                                                    <h6><Link to="/profile">{val.name}</Link> </h6>
                                                                                    <p>{val.activity}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="load-btn">
                                                                <a href="#" className="lab-btn">{btnText} <i className="icofont-spinner"></i></a>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    
                                                    <div className="col-xl-4">
                                                        <aside className="mt-5 mt-xl-0">
                                                            <FilterSearch />
                                                            <LikeMember />
                                                            <ActiveGroup />
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="media" role="tabpanel" aria-labelledby="nav-media-tab">
                                            <div>
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        <article>
                                                            <div className="media-wrapper">
                                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                                    <li className="nav-item" role="presentation">
                                                                        <button className="nav-link active" id="all-media-tab" data-bs-toggle="tab" data-bs-target="#all-media" type="button" role="tab" aria-controls="all-media" aria-selected="true"><i className="icofont-star"></i> All Media</button>
                                                                    </li>
                                                                    <li className="nav-item" role="presentation">
                                                                        <button className="nav-link" id="album-tab" data-bs-toggle="tab" data-bs-target="#album" type="button" role="tab" aria-controls="album" aria-selected="false"><i className="icofont-layout"></i> Albums <span>04</span></button>
                                                                    </li>
                                                                    <li className="nav-item" role="presentation">
                                                                        <button className="nav-link" id="photos-media-tab" data-bs-toggle="tab" data-bs-target="#photos-media" type="button" role="tab" aria-controls="photos-media"  aria-selected="false"><i className="icofont-image"></i> Photos <span>12</span></button>
                                                                    </li>
                                                                    <li className="nav-item" role="presentation">
                                                                        <button className="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video" type="button" role="tab" aria-controls="video" aria-selected="false"><i className="icofont-video-alt"></i> Videos <span>0</span></button>
                                                                    </li>
                                                                    <li className="nav-item" role="presentation">
                                                                        <button className="nav-link" id="music-tab" data-bs-toggle="tab" data-bs-target="#music" type="button" role="tab" aria-controls="music" aria-selected="false"><i className="icofont-music-disk"></i> Music <span>0</span></button>
                                                                    </li>
                                                                </ul>
                                                                <div className="tab-content" id="myTabContent">
                                                                    <div className="tab-pane fade show active" id="all-media"
                                                                        role="tabpanel" aria-labelledby="all-media-tab">
                                                                        <div className="media-title">
                                                                            <h3>Media Gallery</h3>
                                                                        </div>
                                                                        <div className="media-content">
                                                                            <Upload />
                                                                            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4  row-cols-lg-5 row-cols-xl-4 g-3">
                                                                                {MemberList.map((val, i) => (
                                                                                    <div className="col" key={i}>
                                                                                        <div className="media-thumb">
                                                                                            <img src={`${val.imgUrl}`} alt={`${val.imgUrl}`} />
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <div className="load-btn">
                                                                                <a href="#" className="lab-btn">{btnText}<i className="icofont-spinner"></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="tab-pane fade" id="album" role="tabpanel"
                                                                        aria-labelledby="album-tab">
                                                                        <div className="media-title">
                                                                            <h3>Album Lists</h3>
                                                                        </div>
                                                                        <div className="media-content">
                                                                            <Upload />
                                                                            <div className="row g-4">
                                                                                {AlbumList.map((val, i) => (
                                                                                    <div className="col-lg-4 col-sm-6" key={i}>
                                                                                        <div className="album text-center">
                                                                                            <div className="album-thumb">
                                                                                                <a href={val.imgUrl} target="_blank" rel="noopener noreferrer">
                                                                                                    <img src={`${val.imgUrl}`}  alt={`${val.imgAlt}`} />
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className="album-content">
                                                                                                <h6>{val.title}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <div className="load-btn">
                                                                                <a href="#" className="lab-btn">{btnText}<i className="icofont-spinner"></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="tab-pane fade" id="photos-media" role="tabpanel"
                                                                        aria-labelledby="photos-media-tab">
                                                                        <div className="media-title">
                                                                            <h2>All Photos</h2>
                                                                        </div>
                                                                        <div className="media-content">
                                                                            <Upload />
                                                                            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-4 g-3">
                                                                                {GalleryList.map((val, i) => (
                                                                                    <div className="col" key={i}>
                                                                                        <div className="media-thumb">
                                                                                            <img src={`${val.imgUrl}`}  alt={`${val.imgAlt}`} />
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <div className="load-btn">
                                                                                <a href="#" className="lab-btn">{btnText}<i className="icofont-spinner"></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="tab-pane fade" id="video" role="tabpanel"
                                                                        aria-labelledby="video-tab">
                                                                        <div className="media-title">
                                                                            <h3>All Videos</h3>
                                                                        </div>
                                                                        <div className="media-content">
                                                                            <Upload />
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <p><i className="icofont-worried"></i>{opps} </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="tab-pane fade" id="music" role="tabpanel"
                                                                        aria-labelledby="music-tab">
                                                                        <div className="media-title">
                                                                            <h3>All Music</h3>
                                                                        </div>
                                                                        <div className="media-content">
                                                                            <Upload />
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <p><i className="icofont-worried"></i>{opps}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <aside className="mt-5 mt-xl-0">
                                                            <FilterSearch />
                                                            <LikeMember />
                                                            <ActiveGroup />
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="tab-pane fade" id="forums" role="tabpanel" aria-labelledby="nav-forums-tab">
                                            <div className="group-section style2">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        <div className="post-item mb-20">
                                                            <div className="post-content">
                                                                <div className="post-description p-4">
                                                                    <h4 className="mb-0">Forums</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-xl-4">
                                                        <aside className="mt-5 mt-xl-0">
                                                            <FilterSearch />
                                                            <LikeMember />
                                                            <ActiveGroup />
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default GroupSingle;