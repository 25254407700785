import { Component, Fragment } from "react";
import HeaderTwo from "../component/layout/headertwo";
import Footer from "../component/layout/footer";
import PageHeader from "../component/layout/pageheader";
import AboutTwo from "../component/section/abouttwo";
import Pagination from "../component/layout/pagination";
import { Link } from "react-router-dom";
import SelectProduct from "../component/select/select-product";

let MemberList = [
    {
        imgUrl: 'assets/images/member/01.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Tenma Shyna',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Maya Statham',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/03.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Cristina Maria',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/04.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Gaurav-Singh',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/05.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Gihan-Fernando',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/06.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Andrea Guido',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/07.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Sweet Admin',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/08.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Gyan-Baffour',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/09.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Zeahra Maria',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/10.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Amber Cassell',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/11.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Sophia Peeler',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/12.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Luke Prowse',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/13.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Brock Watt',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/14.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Gabriella Osburn',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/15.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Owen Finnerty',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/16.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Alexandra Amiet',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/17.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Makayla Pearce',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/18.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Hudson Tribolet',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/19.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Olivia Roth',
        activity: 'Active 1 Day',
    },
    {
        imgUrl: 'assets/images/member/20.jpg',
        imgAlt: 'Dating Thumb',
        name: 'Ella Carter',
        activity: 'Active 1 Day',
    },
]

class MemberPage extends Component {
    render() { 
        return (
            <Fragment>
                <HeaderTwo />
                <PageHeader title={'All Members'} curPage={'Members'} />
                <AboutTwo />
                <section className="member-page-section padding-tb">
                    <div className="container">
                        <div className="member-wrapper">
                            <ul className="member-info mb-4">
                                <li className="all-member">
                                    <p>All Members </p>
                                    <p>20 365 587</p>
                                </li>
                                <li className="member-cat">
                                    <div className="custom-select right w-100">
                                        <SelectProduct select={'Newest'} />
                                    </div>
                                </li>
                            </ul>
                            <div className="row g-3 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center">
                                {MemberList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="lab-item member-item style-1">
                                            <div className="lab-inner">
                                                <div className="lab-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="lab-content">
                                                    <h6><Link to="/profile">{val.name}<i className="icofont-check" title="Verify Profile"></i></Link> </h6>
                                                    <p>{val.activity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination />
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default MemberPage;