import { Component } from "react";
import SelectAge from "../select/select-age";
import SelectCountry from "../select/select-country";
import SelectGender from "../select/select-gender";
import SelectHobby from "../select/select-hobby";

const title = "Filter Search Member";
const desc = "Serious Dating With PyaarPro Your Perfect Match is Just a Click Away.";
const btnText = "Find Your Partner";

class FilterSearch extends Component {
    render() { 
        return (
            <div className="widget search-widget">
                <div className="widget-inner">
                    <div className="widget-title">
                        <h5>{title}</h5>
                    </div>
                    <div className="widget-content">
                        <p>{desc}</p>
                        <form action="/" className="banner-form">
                            <div className="gender">
                                <div className="custom-select right w-100">
                                    <SelectGender select={'male'} />
                                </div>
                            </div>
                            <div className="person">
                                <div className="custom-select right w-100">
                                    <SelectGender select={'female'} />
                                </div>
                            </div>
                            <div className="age">
                                <div
                                    className="right d-flex justify-content-between w-100">
                                    <div className="custom-select">
                                        <SelectAge select={'18'} />
                                    </div>

                                    <div className="custom-select">
                                        <SelectAge select={'26'} />
                                    </div>
                                </div>
                            </div>
                            <div className="city">
                                <div className="custom-select right w-100">
                                    <SelectCountry select={'Bangladesh'} />
                                </div>
                            </div>
                            <div className="interest">
                                <div className="custom-select right w-100">
                                    <SelectHobby select={'Interests'} />
                                </div>
                            </div>
                            <button>{btnText}</button>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default FilterSearch;