const config = {
    cuenta: "XUGARS",
    url: {
        gateway: "https://gateway.dev.belbom.com",
        panel: "https://panel.ascorptec.com",
        media: "https://belbom-bucket.sfo3.cdn.digitaloceanspaces.com/",
        whatsapp: "https://wa.me"
    },
    googleMaps: {
        key: 'AIzaSyCDmWEU85PfG2G5NeQjWX1dYqkv7jvbOQA',
        places: ['places'],
        containerStyle: {
            height: "100vh",
            width: "100vw"
        },
        zoom: 15,
        center: {
            lat: -0.1779392,
            lng: -78.4813157,
        },
        options: {
            disableDefaultUI: true,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
        }
    },
    geolocation: {
        url: 'https://geolocation-db.com/json',
        key: 'b072f2c0-9d28-11ee-9883-9fb240147f5c',
    },
};

export default config;