import http from "../comunication/http-common";
import config from "../config";

const getGeolocation = async () => {
    const url = config.geolocation.url;
    return await http.get(`${url}/${config.geolocation.key}`)
        .then((result) => result.data)
        .catch((error) => { return { "code": error, "message": error } });
};

export default { getGeolocation }