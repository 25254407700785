// Import Library
import React, { Fragment, useState, useEffect } from "react";

// Import Component
import HeaderTwo from '../component/layout/headertwo';
import BannerTwo from '../component/section/bannertwo';
import AboutTwo from '../component/section/abouttwo';
import AboutThree from '../component/section/aboutthree';
import Story from '../component/section/story';
import Meet from '../component/section/meet';
import Map from "../component/section/map";
import Footer from '../component/layout/footer';
import Loading from '../component/loading/loading';

// Import Hooks
import useCountry from "../hooks/useCountry";

// Import Animation
import animation from "../assets/animation.json";

const HomeTwo = () => {

    const { country, geolocation } = useCountry();
    const [countryCode, setCountryCode] = useState(null);

    useEffect(() => {
        if (!country.loading && !geolocation.loading) {
            const findCountry = country.data.find(c => c.shortCode === geolocation.data.country_code).code;
            if (findCountry) {
                setCountryCode(findCountry);
            }
        }
    }, [country.loading, geolocation.loading]);

    return (
        <Fragment>
            {country.loading || geolocation.loading || !countryCode ? (
                <Loading animation={animation} />
            ) : (
                <Fragment>
                    <HeaderTwo geolocation={geolocation.data} />
                    <BannerTwo />
                    <AboutTwo />
                    <AboutThree />
                    <Story countryCode={countryCode} />
                    <Meet countryCode={countryCode} />
                    <Map />
                    <Footer />
                </Fragment>
            )}
        </Fragment>

    );
}

export default HomeTwo;